<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog title="社保减员" :visible.sync="shows" :close-on-click-modal="false" :close-on-press-escape="false"
			@closed="onclosed">
			<div class="form_box">

				<div class="form_flex">
					<div class="lefts">
						编号
					</div>
					<div class="rights">
						<el-select v-model="form.userId" placeholder="请输入搜索" filterable :filter-method="handleUserSearch" @change="change_user"  :disabled="row_show">
							<el-option v-for="item in user_list" :key="item.id" :label="item.gonghao" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="form_flex" v-if="user_info.name">
					<div class="lefts1">
					</div>
					<div class="rights2">
						<div class="rights_text">
							姓名：{{ user_info.name }}
						</div>
						<div class="rights_text">
							部门：{{ user_info.depart_title }}
						</div>
						<div class="rights_text" style="margin-top: 7px;">
							岗位名称：{{ user_info.level6 }}
						</div>
						<div class="rights_text" style="margin-top: 7px;">
							个人编号：{{ user_info.socNo }}
						</div>
					</div>
				</div>


				<div class="form_flex">
					<div class="lefts">
						中断/终止原因
					</div>
					<div class="rights">
						<el-select v-model="form.out_reason" placeholder="请选择">
							<el-option v-for="item in reason_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="form_flex">
					<div class="lefts">
						减员时间
					</div>
					<div class="rights">
						<el-date-picker v-model="form.out_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>


				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {

		},
		data() {
			return {
				title: 'Add customer information',
				shows: false,
				row_show:false,
				form: {
					userId:'',
					out_time:'',
					out_reason:'',
				},
				user_info:'',


				//
				user_list: [ ],
				user_select: '',
				 
				reason_list: [
					{
						title: '自动离职',
						value: '自动离职'
					},
					{
						title: '辞职',
						value: '辞职'
					},
				],

			};
		},
		methods: {
			onclosed() {
				this.form={
					userId:'',
					out_time:'',
					out_reason:'',
				}
				this.user_info=''
			},
			init(row) {
				this.shows = true;
				if(row){
					this.form.userId=row.userId
					this.row_show=true
					this.get_user()

				}else{
					this.row_show=false
				}
			},
			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
				
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				if (!this.form.userId) {
					alertErr('请选择用户')
					return
				}
				if (!this.form.out_reason) {
					alertErr('请选择中断/终止原因')
					return
				}
				if (!this.form.out_time) {
					alertErr('请选择减员时间')
					return
				}

				this.loading = true
				this.$api("removeSocialEmp", {
					...this.form
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						this.$emit("handle_get_list");

					} else {
						alertErr(res.msg)
					}
				});
			},


			handleUserSearch(value) {
				console.log('处理用户输入', value)

				this.$api("getUserList", {
					page: 1,
					limit: 200,
					keyword:value,
				}, "get").then((res) => {
					if (res.code == 200) {
						this.user_list = res.data.list || []
					} else {

					}
				});

			},
			get_user_list(){
				this.$api("getUserList", {
					page: 1,
					limit: 200,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.user_list = res.data.list||[]
					} else {
						alertErr(res.msg)
					}
				});
			},
			change_user(e){
				console.log(e)
				this.user_list.forEach((v)=>{
					if(v.id==e){
						this.user_info=v
					}
				})
			},
			get_user(){
				this.$api("getUser", {
				 id:this.form.userId
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.user_info = res.data
					} else {
						alertErr(res.msg)
					}
				});
			}
			
		},
		computed: {},

		watch: {

		},


		created() {
			this.get_user_list()

		},


	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts1 {
					width: 120px;
				}

				.rights2 {
					flex: 1;
					border-radius: 4px 4px 4px 4px;
					display: flex;
					flex-wrap: wrap;

					.rights_text {

						width: 210px;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
					}
				}

				.lefts {
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 40px;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;
						border: 1px solid #D4D4D4;

						.el-input__inner {
							// background: #F2F5FA;
							border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>