<template>
	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" :close-on-click-modal="false" :close-on-press-escape="false"
			@closed="onclosed">

			<div class="form_box">
				<div class="form-ctx">
					<div class="form_flex">
						<div class="lefts">
							编号
						</div>
						<div class="rights">
							<el-select v-model="form.userId" placeholder="请输入搜索" filterable :filter-method="handleUserSearch" @change="change_user" :disabled="!!this.form.id">
							<el-option v-for="item in user_list" :key="item.id" :label="item.gonghao" :value="item.id">
							</el-option>
						</el-select>
						</div>
					</div>
					<div class="form_flex" v-if="user_info">
						<div class="lefts1">
								
						</div>
						<div class="rights2">
							<div class="rights_text">
								姓名：{{ user_info.name }}
							</div>
							<div class="rights_text">
								部门：{{ user_info.depart_title }}
							</div>
							<div class="rights_text" style="margin-top: 7px;">
								岗位名称：{{ user_info.level6 }}
							</div>
						</div>
					</div>
					<div class="form_flex">
						<div class="lefts">
							人员编号
						</div>
						<div class="rights">
							<el-input v-model="form.socNo" placeholder="请输入"></el-input>
						</div>
					</div>

					<div class="form_flex">
						<div class="lefts">
							缴存基数
						</div>
						<div class="rights">
							<el-input v-model="form.jc_jishu" placeholder="请输入"></el-input>
						</div>
					</div>
					
					<div class="form_flex" v-for="(item,index) in form_baoxian" :key="index">
						<div class="lefts">
							{{title_obj[item.type]}}：
						</div>
						<div class="rights3">
							<el-input v-model="item.fee" placeholder="费率"></el-input>
							<el-input v-model="item.payable" placeholder="应缴费额"></el-input>
							<el-input v-model="item.reduction" placeholder="减免费额"></el-input>
							<el-input v-model="item.refund" placeholder="应补(退)费额"></el-input>
						</div>
					</div>
					<div class="form_flex">
						<div class="lefts">
							商业意外险：
						</div>
						<div class="rights4">
							<el-radio v-model="form.syyw_insure" label="1">是</el-radio>
							<el-radio v-model="form.syyw_insure" label="0">否</el-radio>
						</div>
					</div>
					<div class="form_flex">
						<div class="lefts">
							安全生产责任险：
						</div>
						<div class="rights4">
							<el-radio v-model="form.aqzr_insure" label="1">是</el-radio>
							<el-radio v-model="form.aqzr_insure" label="0">否</el-radio>
						</div>
					</div>
				</div>

				<div class="bottom_text">
					社保增员后，社保按照增员的月份开始工资的计算
				</div>

				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: "shebao-user-add",
		props: {

		},
		data() {
			return {
				modal_title: '社保增员',
				shows: false,
				form: {
					id: 0,
					socNo: '',
					jc_jishu:'',
					syyw_insure: "1",
					aqzr_insure: "1",
					social:'',
				},

				form_baoxian: [{
				 
					type:10,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:11,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:20,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:21,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:30,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
				 
					type:31,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:40,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:41,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:50,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
				 
					type:51,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:60,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:61,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:70,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}, {
					 
					type:71,
					fee: "",
					payable: "",
					reduction: "",
					refund: ""
				}],


				title_obj:{
					10:'养老保险(单位缴纳)',
					11:'养老保险(个人缴纳)',
					20:'医疗保险(单位缴纳)',
					21:'医疗保险(个人缴纳)',
					30:'工伤保险(单位缴纳)',
					31:'工伤保险(个人缴纳)',
					40:'失业保险(单位缴纳)',
					41:'失业保险(个人缴纳)',
					50:'大额医疗(单位缴纳)',
					51:'大额医疗(个人缴纳)',
					60:'公积金(单位缴纳)',
					61:'公积金(个人缴纳)',
					70:'企业年金(单位缴纳)',
					71:'企业年金(个人缴纳)',
				},

				//
				user_list: [ ],
				user_info:'',
				
			};
		},
		methods: {
			onclosed() {
				this.form = {
					id: 0,
					socNo: '',
					jc_jishu:'',
					syyw_insure: "1",
					aqzr_insure: "1",
					social:'',
				}
				this.user_info=0
				this.form_baoxian=[{
				 
				 type:10,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:11,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:20,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:21,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:30,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
			  
				 type:31,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:40,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:41,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:50,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
			  
				 type:51,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:60,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:61,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:70,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }, {
				  
				 type:71,
				 fee: "",
				 payable: "",
				 reduction: "",
				 refund: ""
			 }]
			},
			init(row) {
				this.shows = true
				if (row) {
					this.get_user_list()
					this.modal_title = '员工社保信息编辑'
					row.syyw_insure=row.syyw_insure+''
					row.aqzr_insure=row.aqzr_insure+''
					this.form=row
					this.form_baoxian.forEach((e,i)=>{
						          
						let type_text='social'+e.type
						if(row[type_text]){
							let obj=row[type_text]
							
							this.form_baoxian[i]=obj
						}
						// else{
						// 	this.form_baoxian[i]={
						// 		type:e.type
						// 	}
						// }
					})
					console.log(row)

				} else {
					this.modal_title = '社保增员'
				}
			},
			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				if (!this.form.userId) {
					alertErr('请选择用户')
					return
				}
				if (!this.form.socNo) {
					alertErr('请输入人员编号')
					return
				}
				if (!this.form.jc_jishu) {
					alertErr('请输入缴存基数')
					return
				}
				for (let i = 0; i <  this.form_baoxian.length; i++) {
					let data =  this.form_baoxian[i];
					if(!data.fee){
						alertErr(`请输入${this.title_obj[data.type]}的费率`)
						return
					}	
					if(!data.payable){
						alertErr(`请输入${this.title_obj[data.type]}的应缴费额`)
						return
					}	
					if(!data.reduction){
						alertErr(`请输入${this.title_obj[data.type]}的减免费额`)
						return
					}	
					if(!data.refund){
						alertErr(`请输入${this.title_obj[data.type]}的应补(退)费额`)
						return
					}	
				}
				this.form.social=JSON.stringify(this.form_baoxian)
				this.loading = true
				this.$api("addSocialEmp", {
					...this.form
				}, "post").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						 
						this.$emit("handle_get_list");

					} else {
						alertErr(res.msg)
					}
				});
			},


			handleUserSearch(value) {
				console.log('处理用户输入', value)

				this.$api("getUserList", {
					page: 1,
					limit: 200,
					keyword: value
				}, "get").then((res) => {
					if (res.code == 200) {
						this.user_list = res.data.list || []
					} else {

					}
				});

			},
			get_user_list(){
				this.$api("getUserList", {
					page: 1,
					limit: 10000,
					keyword: this.form.name||'',
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.user_list = res.data.list||[]
						this.user_list.forEach((e)=>{
							if(e.id==this.form.userId){
								this.user_info=e
							}
						})
					} else {
						alertErr(res.msg)
					}
				});
			},
			change_user(e){
				console.log(e)
				this.user_list.forEach((v)=>{
					if(v.id==e){
						this.user_info=v
						 
					}
				})
			}
		},
		computed: {},

		watch: {

		},


		created() {
			this.get_user_list()


		},


	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {
		/deep/ .el-dialog {
			margin-top: 5vh !important;
		}

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 795px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			margin-top: 0;

			.form-ctx {
				padding-right: 5px;
				max-height: 70vh;
				overflow-y: scroll;
			}

			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts {
					width: 170px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					text-align: right;
					padding-right: 20px;
					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.lefts1 {
					width: 150px;
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 39px;
					border-radius: 4px 4px 4px 4px;

					input {

						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;
						border: 1px solid #D4D4D4;

						.el-input__inner {
							border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}

				.rights3 {
					flex: 1;
					height: 39px;
					border-radius: 4px 4px 4px 4px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					input {
						padding: 0 12px;
						width: 127px;
						height: 40px;
					}

					/deep/.el-input {
						width: 127px;
						border: 1px solid #D4D4D4;
						border-radius: 2px 2px 2px 2px;

						.el-input__inner {
							border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}

				.rights4 {
					flex: 1;
					height: 39px;
					border-radius: 4px 4px 4px 4px;
					display: flex;
					align-items: center;


				}

				.rights2 {
					flex: 1;
					border-radius: 4px 4px 4px 4px;
					display: flex;
					flex-wrap: wrap;

					.rights_text {

						width: 210px;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
					}
				}
			}

			.bottom_text {
				font-weight: 400;
				font-size: 14px;
				color: #CD962A;
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>